@import '../variables';

$danger: #c92424;
$disable-background-color: #eceeef;
$border-color: #ced4da;
$select-border-color: $accent ;

.multiselect-dropdown {
    position: relative;
    width: 100%;
    font-size: inherit;
    font-family: inherit;

    &:focus-visible {
        outline: none !important;
    }

    .dropdown-btn {
        display: inline-flex !important;
        width: 100%;
        margin-bottom: 0;
        font-weight: normal;
        line-height: 1.52857143;
        text-align: left;
        vertical-align: middle;
        cursor: pointer;
        background-image: none;
        min-height: 38px;
        padding: 5px 5px !important;

        border-radius: 10px !important;
        border: 1px solid $border-color !important;

        overflow: hidden !important;
        text-wrap: nowrap !important;

        &:has(.dropdown-multiselect--active) {
            border-bottom-left-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
        }

        span {
            color: $accent !important;
            padding: 3px 6px 0px;
        }

        .selected-item {
            display: flex;
            border: 1px solid $primary !important;
            margin-right: 4px;
            background: $primary !important;
            padding: 0px 5px;
            color: #fff;
            border-radius: 5px !important;
            float: left;
            max-width: 150px !important;
            margin-bottom: 5px !important;
            align-items: center;

            a {
                text-decoration: none;
                padding-left: 6px;
                color: #fff;
                margin: auto;
            }
        }

        .selected-item:hover {
            box-shadow: 1px 1px #b3b3b3;
        }

        .dropdown-multiselect__caret {
            line-height: 16px;
            display: block;
            position: absolute;
            box-sizing: border-box;
            width: 40px;
            height: 38px;
            right: 1px;
            top: 0px;
            padding: 4px 8px;
            margin: 0;
            text-decoration: none;
            text-align: center;
            cursor: pointer;
            transition: transform 0.2s ease;
        }

        .dropdown-multiselect__caret:before {
            color: #999;
            border-width: 5px 5px 2.5px !important;
            border-color: #999 transparent transparent !important;
            vertical-align: sub;
        }

        .dropdown-multiselect--active .dropdown-multiselect__caret {
            transform: rotateZ(180deg);
        }
    }

    .disabled {
        &>span {
            background-color: $disable-background-color;
        }
    }
}

.dropdown-list {
    position: absolute;
    padding-top: 6px;
    width: 100%;
    z-index: 9999;
    border: 1px solid #ccc;
    border-radius: 3px;
    background: #fff;
    margin-top: 10px;

    border-top: none !important;
    border-color: $accent !important;

    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;

    box-shadow: none !important;

    margin-top: -1px !important;

    ul {
        padding: 0px;
        list-style: none;
        overflow: auto;
        margin: 0px;
    }

    li {
        padding: 6px 10px;
        cursor: pointer;
        text-align: left;

        div {
            color: $primary !important;
        }
    }

    .filter-textbox {
        padding: 5px 10px 10px !important;

        input {
            padding: 0px 0px 0px 25px !important;
        }

        input:focus {
            outline: none !important;
        }
    }
}

.multiselect-item-checkbox {
    border-color: $border-color !important;
}

.multiselect-item-checkbox input[type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.multiselect-item-checkbox input[type="checkbox"]:focus+div:before,
.multiselect-item-checkbox input[type="checkbox"]:hover+div:before {
    border-color: $primary !important;
    background-color: #f2f2f2;
}

.multiselect-item-checkbox input[type="checkbox"]:active+div:before {
    transition-duration: 0s;
}

.multiselect-item-checkbox input[type="checkbox"]+div {
    position: relative;
    padding-left: 2em;
    vertical-align: middle;
    user-select: none;
    cursor: pointer;
    margin: 0px;
    color: #000;
}

.multiselect-item-checkbox input[type="checkbox"]+div:before {
    box-sizing: content-box;
    content: "";
    color: $primary !important;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -11px !important;
    text-align: center;
    transition: all 0.4s ease;

    width: 19px !important;
    height: 19px !important;

    border: none !important;
    border-radius: 5px !important;
    background-color: #EBEBEB !important;
}

.multiselect-item-checkbox input[type="checkbox"]+div:after {
    box-sizing: content-box;
    content: "";
    background-color: $primary !important;
    position: absolute;
    top: 50%;
    left: 4px;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    transform: scale(0);
    transform-origin: 50%;
    transition: transform 200ms ease-out;
}

.multiselect-item-checkbox input[type="checkbox"]:disabled+div:before {
    border-color: $border-color;
}

.multiselect-item-checkbox input[type="checkbox"]:disabled:focus+div:before .multiselect-item-checkbox input[type="checkbox"]:disabled:hover+div:before {
    background-color: inherit;
}

.multiselect-item-checkbox input[type="checkbox"]:disabled:checked+div:before {
    background-color: #cccccc;
}

.multiselect-item-checkbox input[type="checkbox"]+div:after {
    background-color: transparent;
    top: 50%;
    left: 4px;
    width: 8px;
    height: 3px;
    margin-top: -4px;
    border-style: solid;
    border-color: #ffffff;
    border-width: 0 0 3px 3px;
    border-image: none;
    transform: rotate(-45deg) scale(0);
}

.multiselect-item-checkbox input[type="checkbox"]:checked+div:after {
    content: "";
    transform: rotate(-45deg) scale(1);
    transition: transform 200ms ease-out;
    margin-top: -5px !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked+div:before {
    animation: borderscale 200ms ease-in;
    background: $primary !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked+div:after {
    transform: rotate(-45deg) scale(1);
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px $primary !important;
    }
}

.selected-item-container {
    .selected-item {
        background-color: $primary !important;
        border-color: $primary !important;
        max-width: 120px !important;
    }
}

// ng-select
.ng-select.ng-select-opened .ng-select-container {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.ng-select>*,
.ng-select .ng-select-container {
    border-color: $select-border-color !important;
}

.ng-select .ng-select-container {
    border-radius: 10px !important;
    border: 1px solid $select-border-color;
    min-height: 31px;

    box-shadow: none !important;
    cursor: pointer !important;

    transition: all .3s ease;

    .ng-input {
        cursor: pointer !important;

        input {
            cursor: pointer !important;
            min-height: 26px;
        }
    }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: none !important;
    border-style: none !important;
    border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
    background-image: url('../../images/ng-select/expand.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin: auto;
}

.ng-select-opened .ng-arrow-wrapper {
    background-image: url('../../images/ng-select/collapse.svg');
}

.ng-select.ng-select-sm {
    min-height: 31px !important;
    align-items: baseline;

    .ng-select-container {
        height: 31px !important;
    }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    color: $select-border-color !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 38px;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container {
    color: $primary !important;

    .ng-input {
        top: 0 !important;
    }

    .ng-value {
        color: $primary !important;
        margin: auto 0;
    }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    .ng-value {
        background-color: $primary !important;
        border-color: $primary !important;
        color: #fff;
        font-size: inherit;
        border-radius: 5px !important;
    }
}

.ng-select.ng-select-disabled>.ng-select-container {
    background-color: #D9D9D9 !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper {
    opacity: 0;
}

.ng-select {
    .ng-arrow-wrapper {
        min-width: 21px;
        padding-right: 5px;
    }

    &.pointer-event-none .ng-arrow-wrapper {
        opacity: 0;
    }

    .ng-clear-wrapper {
        width: auto !important;
        margin: auto;
        padding-inline-start: 5px;
    }

    .ng-spinner-loader {
        width: 18px;
        height: 18px;
        margin: auto;
        margin-right: 5px;
    }
}

ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: $danger;
}

// Dropdown
.ng-dropdown-panel {
    border-top: 0;

    z-index: 1070 !important;

    .ng-dropdown-header {
        border-top: 0.5px solid #ced4da66;
        border-bottom: 0.5px solid #ced4da66;

        button.btn.btn-sm {
            border-radius: 10px;
        }
    }

    .multiselect-dropdown-checkboxes {
        font-weight: inherit;
    }

}

.ng-dropdown-panel.ng-select-bottom {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.ng-dropdown-panel-items {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;

    .ng-option-label,
    .ng-value-label {
        color: $primary !important;
        font-weight: 400;
    }

    .ng-option-marked {
        background-color: #ebebeb !important;
    }

    .ng-option-selected {
        background-color: #ebebeb !important;
        font-weight: 600 !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    white-space: pre-wrap !important;
}

// Date picker
ngb-datepicker-navigation-select>.custom-select {
    flex: 1 1 auto;
    padding: 0 .5rem;
    font-size: .875rem;
    height: 1.85rem;
    border-radius: 10px;
    margin-inline: 0.2rem;
}

ngb-datepicker-navigation-select>.custom-select:focus {
    z-index: 1
}

ngb-datepicker-navigation-select>.custom-select::-ms-value {
    background-color: transparent !important
}
