.flex {
    display: flex !important;
}

.items-baseline {
    align-items: baseline !important;
}

.items-center {
    align-items: center !important;
}

.items-start {
    align-items: flex-start !important;
}

.items-end {
    align-items: flex-end !important;
}

.self-baseline {
    align-self: baseline !important;
}

.self-center {
    align-self: center !important;
}

.self-start {
    align-self: flex-start !important;
}

.self-end {
    align-self: flex-end !important;
}

.flex-direction-row {
    display: flex !important;
    flex-direction: row !important;
}

.flex-direction-row-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
}

.flex-direction-column {
    display: flex !important;
    flex-direction: column !important;
}

.flex-direction-column-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
}
